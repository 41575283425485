import cn from 'classnames';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import type {Article} from '@/components/blog/types';
import BlogSection from '@/components/pages/blog/BlogSection/BlogSection';
import BlogCard from '@/components/pages/blog/BlogCard/BlogCard';
import TwoColumnsFrame from '@/components/pages/blog/BlogSection/TwoColumnsFrame';
import {useTranslations} from '@/hooks/useTranslations';
import {topicEnPath} from '@/components/pages/blog/utils';
import {twMerge} from '@/stylesheets/twMerge';
import {
  blogCardHorizontalTextStyles,
  blogCardBorderStyles,
} from '@/components/pages/blog/BlogCard/style';
import type {Mode} from '@/components/base/types';

interface ResourcesArticlesListProps {
  topArticles: Article[];
  leftArticles: Article[];
  rightArticles: Article[];
  heading: string;
  subHeading: string;
  leftSubHeading?: string;
  enBlogPath: string;
  blogType?: BLOG_PAGE_TYPE;
  isEnableMarketingBlog?: boolean;
  topArticlesTarget?: string;
  mode?: Mode;
}

export default function ResourcesSection({
  topArticles,
  leftArticles,
  rightArticles,
  heading,
  subHeading,
  leftSubHeading,
  enBlogPath,
  blogType,
  isEnableMarketingBlog,
  topArticlesTarget,
  mode,
}: ResourcesArticlesListProps) {
  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;
  const isEngineeringBlog = blogType === BLOG_PAGE_TYPE.ENGINEERING;
  const {t, localizePath} = useTranslations();

  const enterpriseBlogLink = {
    text: t('carouselSeeAllLinkText'),
    url: topicEnPath(enBlogPath, 'enterprise-ecommerce'),
  };

  const engineeringHeaderLink = isEngineeringBlog && {
    text: t('carouselSeeAllLinkText'),
    url: localizePath('/latest'),
  };

  return (
    <BlogSection
      heading={heading}
      link={
        engineeringHeaderLink ||
        (isEnterpriseBlog ? enterpriseBlogLink : undefined)
      }
      className={blogCardHorizontalTextStyles({blogType, mode})}
    >
      {topArticles.length > 0 && (
        <div className="flex flex-col tablet-xl:flex-row gap-6 mb-4 tablet-xl:mb-16">
          {topArticles.map((article: Article, index: number) => (
            <div
              key={'primaryResource' + index}
              className={twMerge(
                cn('border-t pt-4 flex-1', blogCardBorderStyles({blogType})),
              )}
            >
              <BlogCard
                article={article}
                enBlogPath={enBlogPath}
                type="grid-3"
                target={topArticlesTarget}
                preferredTag={
                  isEnterpriseBlog ? article.topicHandle : undefined
                }
                blogType={blogType}
                mode={mode}
              />
            </div>
          ))}
        </div>
      )}
      {leftArticles?.length > 0 && rightArticles?.length > 0 && (
        <TwoColumnsFrame
          isEnableMarketingBlog={isEnableMarketingBlog}
          articles={[...leftArticles, ...rightArticles]}
          enBlogPath={enBlogPath}
          subHeading={subHeading}
          leftSubHeading={leftSubHeading}
          blogType={blogType}
          mode={mode}
        />
      )}
    </BlogSection>
  );
}
