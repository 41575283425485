import {useTranslations} from '@/hooks/useTranslations';
import Icon from '@/components/base/elements/Icon/Icon';
import {IconEnum} from '@/enums';

import {
  wrapperStyles,
  headerWrapperStyles,
  titleStyles,
  subtitleStyles,
  buttonStyles,
  labelStyles,
} from './style';

type SolutionsFragmentProps = {
  isHero?: boolean;
  componentName?: string;
};

export const SolutionsFragment = ({
  isHero = false,
  componentName,
}: SolutionsFragmentProps) => {
  const {t} = useTranslations();
  const buttonData = t('sellAnywhere.button');
  const headingsData = isHero
    ? t('sellAnywhere')
    : t('categoryArticlesConvesion');

  return (
    <div
      className={wrapperStyles({isHero})}
      data-component-name={componentName}
    >
      <div className={headerWrapperStyles({isHero})}>
        {!isHero && <p className={labelStyles}>{headingsData.label}</p>}
        <h2 className={titleStyles({isHero})}>{headingsData.headingHtml}</h2>
        {isHero && (
          <h3 className={subtitleStyles({isHero})}>
            {headingsData.subheadHtml}
          </h3>
        )}
        <a href={buttonData.href} className={buttonStyles({isHero})}>
          <span>{buttonData.text}</span>
          {!isHero && (
            <Icon
              className="w-5 h-5 fill-icon-dark-bg"
              icon={IconEnum.Chevron}
              options={{
                orientation: 'right',
              }}
            />
          )}
        </a>
      </div>
    </div>
  );
};
