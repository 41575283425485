import type {Article, BLOG_PAGE_TYPE} from '@/components/blog/types';
import {useTranslations} from '@/hooks/useTranslations';
import BlogSection from '@/components/pages/blog/BlogSection/BlogSection';
import TwoColumnsFrame from '@/components/pages/blog/BlogSection/TwoColumnsFrame';
import type {Mode} from '@/components/base/types';

interface TwoPaneListProps {
  leftArticles: Article[];
  rightArticles: Article[];
  categoryUrl: string;
  enBlogPath: string;
  heading?: string;
  blogType?: BLOG_PAGE_TYPE;
  mode?: Mode;
}

export default function TwoPane({
  leftArticles,
  rightArticles,
  heading,
  categoryUrl,
  enBlogPath,
  blogType,
  mode,
}: TwoPaneListProps) {
  const {t} = useTranslations();

  return (
    <BlogSection
      heading={heading}
      link={{
        text: t('carouselSeeAllLinkText'),
        url: categoryUrl,
      }}
    >
      <TwoColumnsFrame
        articles={[...leftArticles, ...rightArticles]}
        enBlogPath={enBlogPath}
        blogType={blogType}
        mode={mode}
      />
    </BlogSection>
  );
}
