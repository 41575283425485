import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import type {Article} from '@/components/blog/types';
import BlogCard from '@/components/pages/blog/BlogCard/BlogCard';
import BlogCardHorizontal from '@/components/pages/blog/BlogCard/BlogCardHorizontal';
import Typography from '@/components/base/elements/Typography/Typography';
import {SolutionsFragment} from '@/pages/shopify.com/($locale)/enterprise/blog/components/SolutionsFragment/SolutionsFragment';
import {UpdatesBanner} from '@/components/pages/blog/BlogSection/UpdatesBanner';
import {blogCardBorderStyles} from '@/components/pages/blog/BlogCard/style';
import type {Mode} from '@/components/base/types';

import {subHeaderStyles} from './styles';

const ENTERPRISE_LEFT_TOPICS_SIZE = 4;
const ENGINEERING_LEFT_TOPICS_SIZE = 5;

const LEFT_RIGHT_CORRELATION = 0.4;

interface TwoColumnsFrameProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  articles: Article[];
  enBlogPath: string;
  subHeading?: string;
  leftSubHeading?: string;
  blogType?: BLOG_PAGE_TYPE;
  isEnableMarketingBlog?: boolean;
  mode?: Mode;
}

export default function TwoColumnsFrame({
  articles,
  enBlogPath,
  subHeading,
  leftSubHeading,
  children,
  blogType,
  isEnableMarketingBlog,
  mode,
}: TwoColumnsFrameProps) {
  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;
  const isEngineeringBlog = blogType === BLOG_PAGE_TYPE.ENGINEERING;
  const leftRightСorrelation = LEFT_RIGHT_CORRELATION;
  const leftColumnSize = children
    ? Math.round(articles.length * leftRightСorrelation) + 1
    : Math.round(articles.length * leftRightСorrelation);
  let columnSize = leftColumnSize;
  if (isEnterpriseBlog) {
    columnSize = ENTERPRISE_LEFT_TOPICS_SIZE;
  } else if (isEngineeringBlog) {
    columnSize = ENGINEERING_LEFT_TOPICS_SIZE;
  }

  const leftColumn = articles.slice(0, columnSize);
  const rightColumn = articles.slice(columnSize);
  const hasRightColumn = rightColumn.length > 0;

  return (
    <div className={hasRightColumn ? 'tablet-xl:flex' : ''}>
      <div
        className={twMerge(
          'flex flex-col space-y-6 tablet:space-y-5',
          hasRightColumn
            ? cn(
                'tablet-xl:pr-4 desktop:pr-8 desktop:border-r',
                blogCardBorderStyles({blogType}),
                'basis-3/5 pb-9 tablet-xl:pb-0',
              )
            : '',
        )}
      >
        {leftSubHeading && (
          <Typography as="h6" className={subHeaderStyles}>
            {leftSubHeading}
          </Typography>
        )}
        {leftColumn.map((article: Article, index: number) => (
          <div key={article.path ?? article.handle + index}>
            <BlogCardHorizontal
              blogType={blogType}
              article={article}
              enBlogPath={enBlogPath}
              preferredTag={isEnterpriseBlog ? article.topicHandle : undefined}
              mode={mode}
            />
          </div>
        ))}
      </div>
      {hasRightColumn && (
        <div
          className={twMerge(
            cn(
              'tablet-xl:pl-4 desktop:pl-8 desktop:border-l -ml-px',
              'flex flex-col basis-2/5 space-y-6 tablet:space-y-5',
              blogCardBorderStyles({blogType}),
            ),
          )}
        >
          {isEnterpriseBlog && isEnableMarketingBlog && <SolutionsFragment />}
          {isEngineeringBlog && <UpdatesBanner target="_blank" />}
          {children}
          {subHeading && (
            <Typography as="h6" className={subHeaderStyles}>
              {subHeading}
            </Typography>
          )}
          {rightColumn.map((article: Article, index: number) => (
            <div
              key={article.path ?? article.handle + index}
              className={twMerge(
                cn(
                  'border-t tablet:border-none',
                  blogCardBorderStyles({blogType}),
                ),
              )}
            >
              <BlogCard
                article={article}
                enBlogPath={enBlogPath}
                type="noImage"
                preferredTag={
                  isEnterpriseBlog ? article.topicHandle : undefined
                }
                blogType={blogType}
                mode={mode}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
