import {useLoaderData} from '@remix-run/react';

import Icon from '@/components/base/elements/Icon/Icon';
import {IconEnum} from '@/enums';
import {useTranslations} from '@/components/shared/Page/I18N';
import type {IndexSectionArticles} from '@/utils/server/blog/indexPageDefinitions/types.server';

export const UpdatesBanner = ({target = '_self'}) => {
  const {t} = useTranslations();
  const {localizedSettings} = useLoaderData<IndexSectionArticles>();

  return (
    <div className="p-6 bg-engineering-dark-author-text">
      <div className="uppercase text-[12px] font-medium mb-2 text-engineering-light-text">
        {t('updatesBanner.topText')}
      </div>
      <h3 className="text-t4 font-medium max-w-[340px] mb-[72px] text-engineering-light-text">
        {t('updatesBanner.title')}
      </h3>
      <a
        className="flex justify-between items-center text-engineering-light-text"
        href={localizedSettings?.threePane.bannerUrl}
        target={target}
        {...(target === '_blank' ? {rel: 'noopener noreferrer'} : {})}
      >
        <span>{t('updatesBanner.linkText')}</span>
        <Icon
          className="w-5 h-5 fill-icon-dark-bg"
          icon={IconEnum.Chevron}
          options={{
            orientation: 'right',
          }}
        />
      </a>
    </div>
  );
};
