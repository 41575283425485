import {cx, cva} from 'class-variance-authority';

export const wrapperStyles = cva('flex w-full flex-col', {
  variants: {
    isHero: {
      true: 'p-9 items-center bg-[#0E130E]',
      false: 'p-6 items-start bg-section-dark-bg',
    },
  },
});
export const headerWrapperStyles = cva('flex w-full flex-col gap-2', {
  variants: {
    isHero: {
      true: 'items-center',
      false: 'items-start',
    },
  },
});

export const titleStyles = cva('font-normal', {
  variants: {
    isHero: {
      true: 'text-[#CDF986] text-xl text-center',
      false: 'text-white text-3xl',
    },
  },
});

export const subtitleStyles = cva('font-normal', {
  variants: {
    isHero: {
      true: 'text-white text-sm text-center font-shopifysans',
      false: '',
    },
  },
});

export const buttonStyles = cva('text-base mt-6 font-faktum font-medium', {
  variants: {
    isHero: {
      true: 'bg-[#CDF986] py-2.5 px-6 rounded-[30px] text-black',
      false: 'w-full text-[#CDF986] flex justify-between align-center ',
    },
  },
});

export const labelStyles = cx('text-xs uppercase text-white');
